import Vue from "vue";

export default {
    recuperarInstituicoesEnsino() {
        return Vue.axios.get("instituicaoEnsino");
    },

    recuperarInstituicaoEnsinoPorOid(iesOid) {
        return Vue.axios.get("instituicaoEnsino/" + iesOid);
    },

    recuperarFiltro(paramsFiltro, pagina, numElementosPagina, ordenacao) {
        return Vue.axios.post("instituicaoEnsino/filtro", {
            params: {
                sigla: paramsFiltro.sigla,
                nome: paramsFiltro.nome,
            },
            pagina,
            numElementosPagina,
            ordenacao
        });
    },

    criarIES(dados) {
        return Vue.axios.post("instituicaoEnsino", {
            nome: dados.nome,
            sigla: dados.sigla
        });
    },

    atualizarIES(dados) {
        return Vue.axios.put("instituicaoEnsino", {
            oid: dados.oid,
            nome: dados.nome,
            sigla: dados.sigla
        });
    }
};