<template>
  <v-container fluid>
    <v-row dense justify="space-between">
      <v-col cols="12">
        <EscolhaOpcaoSelect :inscricao="inscricao" :opcoes="processo.opcoes"/>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
            v-model="inscricaoICA.instituicaoEnsino"
            :item-text="
            item => (item.nome + (item.sigla ? ' - ' + item.sigla : ''))
          "
            :items="instituicoesEnsino"
            :rules="[campoObrigatorioRule(inscricaoICA.instituicaoEnsino.oid)]"
            dense
            label="Instituição de Ensino em que se graduou *"
            outlined
            return-object
            validate-on-blur
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
            v-model="inscricaoICA.anoConclusaoCursoAnterior"
            v-mask="'############'"
            :rules="[campoObrigatorioRule]"
            dense
            label="Ano em que concluiu a graduação anterior *"
            name="anoConclusaoCursoAnterior"
            maxLength="4"
            outlined
            validate-on-blur
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import EscolhaOpcaoSelect from "@/components/pages/Inscricao/EscolhaOpcaoSelect";
import instituicaoEnsinoService from "@/services/instituicaoEnsino.service";

export default {
  name: "InscricaoFromICA",

  components: {
    EscolhaOpcaoSelect
  },

  props: {
    processo: {type: Object, required: true},
    inscricao: {type: Object, required: true}
  },

  data() {
    return {
      instituicoesEnsino: [],
      inscricaoICA: {
        inscricao: null,
        anoConclusaoCursoAnterior: null,
        instituicaoEnsino: {}
      }
    };
  },

  async created() {
    await instituicaoEnsinoService
        .recuperarInstituicoesEnsino()
        .then(response => {
          this.instituicoesEnsino = response.data;
        })
        .catch(() => {
        });
  },

  watch: {
    inscricaoICA: {
      handler(){
        this.inscricaoICA.inscricao = this.inscricao;
        this.$emit("input", this.inscricaoICA);
      },
      deep: true
    }
  },

  methods: {
    ...rules
  }
}
</script>

<style scoped>

</style>
